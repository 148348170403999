import axios, { headersTags } from '@/libs/axios'
import harvests from '@/apis/harvests'
import moment from '@/libs/moment'

/**
 @typedef HistoryCropHarvest
 @property {String} date
 @property {Array<CropHarvest>} cropHarvest
 @property {string} pulled_at
 */

/**
 @typedef CropHarvest
 @property {Number} id
 @property {string} color
 @property {string} emoji
 @property {string} name
 @property {Number} quantity
 @property {Number} total_weight
 @property {StaffMemberHarvest[]} harvest
 */

/**
 @typedef StaffMemberHarvest
 @property {Number} id
 @property {string} code
 @property {string} firstname
 @property {string} lastname
 @property {string} language
 @property {Number} quantity
 @property {Number} total_weight
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<HistoryCropHarvest>} list */
    list: null,
    /** @type {string|null} lastPull */
    lastPull: null,
    /** @type {Number} currentPage */
    currentPage: 0,
    /** @type {Number} total */
    total: 0,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
        && state.currentPage !== 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasOne(state) {
      return state.list !== null && state.list.length > 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasAll(state) {
      return state.list !== null && state.list.length === state.total
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.length >= 1
    },
    /**
     * @param state
     * @returns {Array<HistoryCropHarvest>}
     */
    getList(state) {
      return state.list.filter(e => moment(e.date)
        .locale('en')
        .startOf('day')
        .isBefore(moment()
          .locale('en')
          .startOf('day'))) // TODO Apply this fix in other apps
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return moment(state.lastPull)
    },
    /**
     * @param state
     * @returns {Array<HistoryCropHarvest>}
     */
    getListOrderByMonth(state) {
      const ordered = {}

      state.list.filter(e => moment(e.date)
        .locale('en')
        .startOf('day')
        .isBefore(moment()
          .locale('en')
          .startOf('day'))) // TODO Apply this fix in other apps
        .forEach(e => {
          const month = `${e.date.split('-')[0]}-${e.date.split('-')[1]}`
          if (typeof ordered[month] === 'undefined') {
            ordered[month] = []
          }

          ordered[month].push(e)
        })

      return ordered
    },
  },
  mutations: {
    clear(state) {
      state.list = null
      state.lastPull = null
      state.currentPage = 0
    },
    /**
     * @param state
     * @param {Array<Farmer>} list
     * @param {Number} currentPage
     * @param {Number} total
     * @returns {Promise<void>}
     */
    set(state, {
      list,
      currentPage,
      total,
    }) {
      state.list = (state.list === null ? [...list] : [...state.list, ...list])
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD')
      state.total = total
      state.currentPage = currentPage
    },
    /**
     * @param state
     * @param {String} date
     * @param {array<CropHarvest>} cropHarvest
     */
    saveHarvestCrop(state, {
      date,
      cropHarvest,
    }) {
      const element = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))[0]
      if (typeof element === 'undefined') return

      const endpoint = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))[0]
      endpoint.cropHarvest = cropHarvest
      endpoint.pulled_at = moment()
        .locale('en')
        .format('YYYY-MM-DD')
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      state,
      getters,
      rootGetters,
    }, clearBefore = false) {
      /** @type {AxiosResponse<loginResponse>} response */
      const response = await (axios(harvests.calendar(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          page: clearBefore ? 1 : state.currentPage + 1,
        },
        {
          [headersTags.ORDER_BY]: JSON.stringify({
            end: 'desc',
            start: 'desc',
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
        currentPage: response.data?.last_page <= response.data?.current_page ? response.data?.last_page : response.data?.current_page,
        total: response.data?.total,
      })

      return (getters.hasAll)
    },
    /**
     * @param state
     * @param rootGetters
     * @param commit
     * @param {String} date
     * @param {Object} filters
     * @param {boolean} force
     * @param {boolean} byPassStorageCaching
     *
     * @return {Boolean|Object}
     */
    async load({
      state,
      rootGetters,
      commit,
    }, {
      date,
      filters = {},
      force = false,
      byPassStorageCaching = false,
    }) {
      const find = state.list === null ? null : state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (
        !force
        && find.length !== 0
        && typeof find[0].cropHarvest !== 'undefined'
        && find[0].cropHarvest !== null
      ) {
        return true
      }

      const response = await (axios(harvests.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        date,
        {
          ...filters,
        },
        {
          [headersTags.FIELDS]: JSON.stringify([
            'id',
            'emoji',
            'name',
            'color',
            'harvest',
            'quantity',
            'total_weight',
          ]),
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (byPassStorageCaching) {
        return {
          cropHarvest: response.data?.data,
          pulled_at: moment()
            .locale('en')
            .format('YYYY-MM-DD'),
        }
      }

      commit('saveHarvestCrop', {
        date,
        cropHarvest: response.data?.data,
      })
      return true
    },
    /**
     * @param state
     * @param rootGetters
     * @param commit
     * @param {String} date
     * @param {Object} filters
     * @param {boolean} force
     * @param {boolean} byPassStorageCaching
     *
     * @return {Boolean|Object}
     */
    async loadWithoutStore({
      state,
      rootGetters,
      commit,
    }, {
      date,
      filters = {},
      force = false,
      byPassStorageCaching = false,
    }) {
      const find = state.list === null ? null : state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))

      if (
        !force
        && find.length !== 0
        && typeof find[0].cropHarvest !== 'undefined'
        && find[0].cropHarvest !== null
      ) {
        return true
      }

      const response = await (axios(harvests.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        date,
        {
          ...filters,
        },
        {
          [headersTags.FIELDS]: JSON.stringify([
            'id',
            'emoji',
            'name',
            'color',
            'harvest',
            'quantity',
            'total_weight',
          ]),
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (byPassStorageCaching) {
        return {
          cropHarvest: response.data?.data,
          pulled_at: moment()
            .locale('en')
            .format('YYYY-MM-DD'),
        }
      }

      commit('saveHarvestCrop', {
        date,
        cropHarvest: response.data?.data,
      })
      return true
    },
    /**
     * @param state
     * @param {String} date
     */
    has({
      state,
    }, date) {
      if (state.list === null) return null

      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      return (find.length >= 1)
    },
    /**
     * @param state
     * @param {String} date
     */
    find({
      state,
    }, date) {
      if (state.list === null) return null

      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (find.length >= 1) return find[0]

      return null
    },
    /**
     * @param state
     * @param {String} date
     * @param {null|Number} crop
     * @returns {HistoryCropHarvest|null}
     */
    getHarvestCrop({
      state,
    }, {
      date,
    }) {
      if (state.list === null) return null
      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (find.length !== 1) return null

      return find[0]
    },
  },
}
