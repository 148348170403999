export default {
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String} date
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerId, date, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/staff/members/harvest/${date}`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  calendar(token, farmerId, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/staff/members/harvest`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },

}
