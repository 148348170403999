import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import campaigns from '@/store/campaigns'
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import farmers from './farmers'
import harvestNow from './harvest/now'
import harvestHistory from './harvest/history'
import server from './server'
import settings from './settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    farmers,
    campaigns,
    harvestNow,
    harvestHistory,
    server,
    settings,
  },
  strict: process.env.DEV,
})
