import Vue from 'vue'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Device } from '@capacitor/device'
import VueCompositionAPI from '@vue/composition-api'
import Vue2TouchEvents from 'vue2-touch-events'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueScroller from '@/@core/components/pull-to-refresh/index'
import VueView from '@/@core/components/view/index'

import config from '@/config.json'
import i18n from '@/libs/i18n'
import mitt from 'mitt'
import axios from '@axios'
import moment from '@moment'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/helper'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'vue-transitions-css'

// Plugin Registration
Vue.use(Vue2TouchEvents)
Vue.use(VueScroller)
Vue.use(VueView)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@fortawesome/fontawesome-pro/css/all.css') // For form-wizard
require('animate.css/animate.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.emitter = mitt()
Vue.prototype.$moment = moment
Vue.prototype.$http = axios
Vue.prototype.$config = config
Vue.prototype.$window = window
Vue.config.productionTip = false
Vue.config.ignoredElements = ['h7']

window.isReady = false;

(async () => {
  Bugsnag.start({
    apiKey: config.bugsnag.apiKey,
    plugins: [new BugsnagPluginVue()],
  })

  const deviceInfos = await Device.getInfo()
  if (deviceInfos.platform !== 'web') {
    await StatusBar.setStyle({
      style: Style.Light,
    })
  }

  await store.dispatch('server/restore')
  Vue.prototype.$http.defaults.baseURL = store.getters['server/getUrl']

  await store.dispatch('auth/restore')
  await store.dispatch('farmers/restore')
  await store.dispatch('settings/restore')

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})()
