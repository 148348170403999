import axios, { headersTags } from '@/libs/axios'
import campaigns from '@/apis/campaigns'
import { App } from '@capacitor/app'

/**
 @typedef Campaign
 @property {Number|String} id
 @property {Number|String} farmer_id
 @property {String} name
 @property {string} start
 @property {string} end
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<Campaign>} list */
    list: null,
    /** @type {Number} currentPage */
    currentPage: 0,
    /** @type {Number} total */
    total: 0,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.currentPage !== 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasOne(state) {
      return state.list !== null && state.list.length > 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasAll(state) {
      return state.list !== null && state.list.length === state.total
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {Array<Campaign>}
     */
    getList(state) {
      return state.list
    },
  },
  mutations: {
    /**
     * @param {Object} state
     */
    clear(state) {
      state.list = null
      state.currentPage = 0
    },
    /**
     * @param {Object} state
     * @param {Array<Farmer>} list
     * @param {Number} currentPage
     * @param {Number} total
     * @returns {Promise<void>}
     */
    set(state, {
      list,
      currentPage,
      total,
    }) {
      state.list = state.list === null ? [...list] : [...state.list, ...list]
      state.total = total
      state.currentPage = currentPage
    },
    /**
     * @param {Object} state
     * @param {Campaign} campaign
     */
    update(state, campaign) {
      if (state.list === null) return

      state.list = state.list.map(e => {
        // eslint-disable-next-line radix
        if (parseInt(e.id) === parseInt(campaign.id)) {
          return JSON.parse(JSON.stringify(campaign))
        }

        return e
      })
    },
    /**
     * @param {Object} state
     * @param {Campaign} campaign
     */
    push(state, campaign) {
      if (state.list === null) return

      state.list.push(campaign)
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     * @param {Array} stats
     */
    addStats(state, {
      campaignId,
      stats,
    }) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.stats = stats
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     */
    clearStats(state, campaignId) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.stats = undefined
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     * @param {Array} units
     */
    addUnits(state, {
      campaignId,
      units,
    }) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.units = units
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     */
    clearUnits(state, campaignId) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.months = undefined
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     * @param {Array} progressions
     */
    addProgressions(state, {
      campaignId,
      progressions,
    }) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.progressions = progressions
    },
    /**
     * @param {Object} state
     * @param {Number|String} campaignId
     */
    clearProgressions(state, campaignId) {
      // eslint-disable-next-line radix
      const r = state.list.filter(e => parseInt(e.id) === parseInt(campaignId))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      r.progressions = undefined
    },
  },
  actions: {
    /**
     * @param {Function} commit
     * @param {Object} state
     * @param {Object} getters
     * @param {Object} rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      state,
      getters,
      rootGetters,
    }, clearBefore = false) {
      /** @type {AxiosResponse<loginResponse>} response */
      const response = await (axios(campaigns.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          page: clearBefore ? 1 : state.currentPage + 1,
          reserved_for_app: await (async () => {
            try {
              return (await App.getInfo())?.id
            } catch (e) {
              return rootGetters['auth/getDeviceToken']
            }
          })(),
        },
        {
          [headersTags.ORDER_BY]: JSON.stringify({
            end: 'desc',
            start: 'desc',
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
        currentPage: response.data?.meta?.last_page <= response.data?.meta?.current_page ? response.data?.meta?.last_page : response.data?.meta?.current_page,
        total: response.data?.meta?.total,
      })

      return (getters.hasAll)
    },
    /**
     * @param {Object} rootGetters
     * @param {Function} dispatch
     * @param {String} name
     * @param {String} startDate
     * @param {String} endDate
     * @param {Boolean} reservedForApp
     * @returns {Promise<boolean>}
     */
    async add({
      rootGetters,
      dispatch,
    }, {
      name,
      startDate,
      endDate,
      reservedForApp,
    }) {
      const response = await axios(campaigns.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          name,
          start: startDate,
          end: endDate,
          reserved_for_app: (reservedForApp ? await (async () => {
            try {
              return (await App.getInfo())?.id
            } catch (e) {
              return rootGetters['auth/getDeviceToken']
            }
          })() : null),
        },
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Object} rootGetters
     * @param {Function} dispatch
     * @param {Number} id
     * @param {String} name
     * @param {String} startDate
     * @param {String} endDate
     * @param {Boolean} reservedForApp
     * @returns {Promise<boolean>}
     */
    async edit({
      rootGetters,
      dispatch,
    }, {
      id,
      name,
      startDate,
      endDate,
      reservedForApp,
    }) {
      const response = await axios(campaigns.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {
          name,
          start: startDate,
          end: endDate,
          reserved_for_app: (reservedForApp ? await (async () => {
            try {
              return (await App.getInfo())?.id
            } catch (e) {
              return rootGetters['auth/getDeviceToken']
            }
          })() : null),
        },
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Object} getters
     * @param {Number|String} id
     * @returns {Promise<Campaign>}
     */
    async find({ getters }, id) {
      // eslint-disable-next-line radix
      const r = getters.getList.filter(e => parseInt(e.id) === parseInt(id))[0]
      if (typeof r === 'undefined') throw Error('Cannot find campaign')

      return r
    },
    /**
     * @param {Object} getters
     * @param {Number} id
     * @returns {Promise<boolean>}
     */
    async delete({
      rootGetters,
      dispatch,
    }, id) {
      const response = await axios(campaigns.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {Object} rootGetters
     * @param {Number} id
     * @param {Boolean} force
     * @param {Boolean} byPassStorageCaching
     * @param {String} startDate
     * @param {String} endDate
     * @returns {Promise<*>}
     */
    // eslint-disable-next-line consistent-return
    async fetchStats({
      commit,
      dispatch,
      rootGetters,
    }, {
      id,
      force = false,
      byPassStorageCaching = false,
      startDate = null,
      endDate = null,
    }) {
      const data = {}

      if (startDate !== null) {
        data.start = startDate
      }

      if (endDate !== null) {
        data.end = endDate
      }

      if (force || typeof (await dispatch('find', id))?.stats === 'undefined') {
        const response = await axios(campaigns.stats(
          rootGetters['auth/getToken'],
          rootGetters['farmers/getDefaultFarmer']?.id,
          id,
          data,
        ))

        if (byPassStorageCaching) return response.data

        commit('addStats', {
          campaignId: id,
          stats: response.data,
        })
      }
    },
    /**
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {Object} rootGetters
     * @param {Number} id
     * @param {String} unit
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async fetchUnits({
      commit,
      dispatch,
      rootGetters,
    }, {
      id,
      unit,
      force = false,
    }) {
      if (force || typeof (await dispatch('find', id)).units === 'undefined') {
        const response = await axios(campaigns.units(
          rootGetters['auth/getToken'],
          rootGetters['farmers/getDefaultFarmer']?.id,
          id,
          unit,
        ))
        commit('addUnits', {
          campaignId: id,
          // eslint-disable-next-line no-shadow
          units: response.data.data.map(unit => unit.unit),
        })
      }
    },
    /**
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {Object} rootGetters
     * @param {Number} id
     * @param {String} unit
     * @param {Boolean} force
     * @param {Boolean} byPassStorageCaching
     * @param {String} startDate
     * @param {String} endDate
     * @returns {Promise<*>}
     */
    // eslint-disable-next-line consistent-return
    async fetchProgression({
      commit,
      dispatch,
      rootGetters,
    }, {
      id,
      unit,
      force = false,
      byPassStorageCaching = false,
      startDate = null,
      endDate = null,
    }) {
      const data = {}

      if (startDate !== null) {
        data.start = startDate
      }

      if (endDate !== null) {
        data.end = endDate
      }

      if (force || typeof (await dispatch('find', id))?.progressions === 'undefined') {
        const response = await axios(campaigns.progression(
          rootGetters['auth/getToken'],
          rootGetters['farmers/getDefaultFarmer']?.id,
          id,
          unit,
          data,
        ))

        if (byPassStorageCaching) return response.data

        commit('addProgressions', {
          campaignId: id,
          progressions: response.data,
        })
      }
    },
  },
}
