export const NAME_HISTORY = 'history'
export const NAME_HISTORY_DATE = 'history-date'

export default [
  {
    path: '/history',
    name: NAME_HISTORY,
    component: () => import('@/views/pages/Histories/History.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/history/:date',
    name: NAME_HISTORY_DATE,
    component: () => import('@/views/pages/Histories/HistoryDay.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
