export const NAME_NOW = 'now'

export default [
  {
    path: '/now',
    name: NAME_NOW,
    component: () => import('@/views/pages/Now/Now.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
